import { Link as GatsbyLink } from "gatsby"
import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import config from "../../config"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const UniversalLink = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const regex = /^(?!(?:javascript|data|chrome|mailto|tel|sms|callto|mms|skype):).+$/
  const internal = to.search(process.env.GATSBY_WP_URL)
  const http = to.search("http")
  const location = useLocation()
  const urlParams = location.search
  const [updatedTo, setUpdatedTo] = useState(false)

  useEffect(() => {
    const params = window.location.search

    if (
      params.search("utm_medium") &&
      to.search(config.onboardingHost) !== -1
    ) {
      setUpdatedTo(to + params)
    }
  })

  // Use Gatsby Link for internal links, and <a> for others
  if ((http == -1 || internal > -1) && regex.test(to)) {
    return (
      <GatsbyLink
        to={internal > -1 ? to.replace(process.env.GATSBY_WP_URL, "") : to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={updatedTo ? updatedTo : to} {...other} target="_blank">
      {children}
    </a>
  )
}
export default UniversalLink
