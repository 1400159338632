import React from "react"

import NavigationDropdown from "./NavigationDropdown"
import UniversalLink from "../UniversalLink"
import { useLocation } from "@reach/router"

export default ({ menuItem, setdropDownOpen, setShow }) => {
  const children = menuItem.childItems.nodes.length
    ? menuItem.childItems.nodes
    : null

  const location = useLocation()
  const activeRoute = location.pathname == menuItem.path

  return (
    <li className={`navigation__item ${menuItem.cssClasses}`} key={menuItem.id}>
      {children ? (
        <NavigationDropdown
          parent={menuItem}
          children={children}
          setdropDownOpen={setdropDownOpen}
        />
      ) : activeRoute ? (
        <div
          className="navigation__link"
          onClick={() => setShow(false)}
          to={menuItem.path}
        >
          {menuItem.label}
        </div>
      ) : (
        <UniversalLink
          className={`navigation__link ${
            children ? "js-dropdown-toggle has--children" : null
          }`}
          to={menuItem.path}
          activeClassName="is--active"
        >
          {menuItem.label}
        </UniversalLink>
      )}
    </li>
  )
}
