import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import Cookies from "universal-cookie"
import contentParser from "gatsby-wpgraphql-inline-images"
import config from "../../config"

import CookieBanner from "./CookieBanner/CookieBanner"
import FreshChat from "./FreshChat/FreshChat"
import Overlay from "./Overlay/Overlay"
import ImageTextOverlay from "./ImageTextOverlay/ImageTextOverlay"

import Header from "./Header/Header"
import Head from "./Head"
import Footer from "./Footer/Footer"
import Notification from "./Notification/Notification"
import notificationContext from "../context/NotificationContext"

import "../sass/main.scss"
import "./Header/Banner.scss"

const Layout = ({
  menuColor,
  hideNavigation,
  hideButton,
  hideBanner,
  cleanFooter,
  children,
  whiteBackgroundMenu,
}) => {
  const { wp } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
        themeOptions {
          ACFThemePageLink {
            promoBanner
          }
        }

        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  extension
                  publicURL
                }
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)
  const [message, setMessage] = useState("")

  const [freshChatLoaded, setfreshChatLoaded] = useState(false)

  const promoBanner = wp.themeOptions.ACFThemePageLink.promoBanner
  const [showOverlay, setShowOverlay] = useState(false)
  const [showImageTextOverlay, setShowImageTextOverlay] = useState(false)
  const clickButton = event => {
    const href = event.target.getAttribute("href")
      ? event.target.getAttribute("href")
      : ""

    if (href.indexOf("#beta-overlay") > -1) {
      event.preventDefault()
      document.body.style.overflow = "hidden"

      setShowOverlay(true)
    } else if (href.indexOf("#show-chat") > -1) {
      event.preventDefault()
      window.fcWidget.open()
    }
  }

  React.useEffect(() => {
    window.addEventListener("click", e => clickButton(e))

    return () => {
      window.removeEventListener("click", e => clickButton(e))
    }
  }, [])

  React.useEffect(() => {
    window.onFreshLoad = () => {
      setfreshChatLoaded(true)
    }
    const cookies = new Cookies()

    if (
      cookies.get(config.cookieSettings.functionalCookie) &&
      cookies.get(config.cookieSettings.functionalCookie) != "false"
    ) {
      const script = document.createElement("script")

      script.addEventListener("load", event => {
        setfreshChatLoaded(true)
      })
      script.src = "https://wchat.freshchat.com/js/widget.js"
      document.head.appendChild(script)
    } else {
      setfreshChatLoaded(false)
      if (window.fcWidget) {
        window.fcWidget.destroy()
      }
    }

    return () => {
      delete window.onFreshLoad()
    }
  }, [])

  const options = {
    schemaReplacement: {
      from: process.env.GATSBY_WP_URL,
      to: "https://metro-fs.com/",
    },
  }

  return (
    <SEOContext.Provider value={{ global: wp.seo, options }}>
      <notificationContext.Provider value={{ message, setMessage }}>
        <Head />
        <div
          className={`body-wrapper ${
            promoBanner && !hideBanner ? "has--banner" : ""
          }`}
        >
          {promoBanner && !hideBanner ? (
            <div
              className="banner"
              onClick={() => {
                setShowImageTextOverlay(true)
                document.body.style.overflow = "hidden"
              }}
            >
              {contentParser(
                { content: promoBanner },
                config.inlineImagesOptions
              )}
            </div>
          ) : null}
          <Header
            menuColor={menuColor}
            hideNavigation={hideNavigation}
            hideButton={hideButton}
            whiteBackgroundMenu={whiteBackgroundMenu}
          />
          <main id="content" role="main" className="content-wrapper">
            {children}
          </main>
          <Notification message={message} />

          {freshChatLoaded && <FreshChat />}
          <Overlay showOverlay={showOverlay} setShowOverlay={setShowOverlay} />
          <ImageTextOverlay
            showOverlay={showImageTextOverlay}
            setShowOverlay={setShowImageTextOverlay}
          />

          <Footer cleanFooter={cleanFooter} />
          <CookieBanner />
        </div>
      </notificationContext.Provider>
    </SEOContext.Provider>
  )
}

export default Layout
