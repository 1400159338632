import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import UniversalLink from "../UniversalLink"
import Image from "../Image/Image"
import "./ImageTextOverlay.scss"

import SVGParser from "../SVGParser"
const ImageTextOverlay = props => {
  const { wp } = useStaticQuery(graphql`
    query ImageTextOverlay {
      wp {
        themeOptions {
          ACFThemePageLink {
            imageTextOverlay {
              icon
              heading
              subheading
              text
              button {
                title
                url
              }
              linkId
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    icon,
    heading,
    subheading,
    text,
    button,
    image,
    linkId,
  } = wp.themeOptions.ACFThemePageLink.imageTextOverlay

  const { showOverlay, setShowOverlay } = props

  return (
    <div className={`image-text-overlay ${showOverlay ? "is--active" : ""}`}>
      <div className="container image-text-overlay__container">
        <a
          className="image-text-overlay__close-btn"
          onClick={() => {
            setShowOverlay(false)
            document.body.style.overflow = "visible"
          }}
        >
          <SVGParser name="overlay-close" />
        </a>
        <div className={`image-text-overlay__card-wrapper`}>
          <div className="image-text-overlay__text-wrapper">
            <SVGParser classes="image-text-overlay__icon" name={icon} />
            <h3 className={`image-text-overlay__heading`}>{heading}</h3>
            <div className="image-text-overlay__subheading">{subheading}</div>
            <div className={`image-text-overlay__text`}>{text}</div>
            {button && (
              <UniversalLink to={button.url} className="button" id={linkId}>
                {button.title}
              </UniversalLink>
            )}
          </div>

          <div className="image-text-overlay__img-wrapper">
            {image && (
              <div>
                <Image classes={`image-text-overlay__image`} image={image} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageTextOverlay
