import React, { useState } from "react"
import "./footer.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import UniversalLink from "../UniversalLink"
import { Collapse } from "react-collapse"

const FooterNavigation = ({ menuItem, lastElement }) => {
  const [show, setShow] = useState(false)
  const breakpoints = useBreakpoint()

  return (
    <div
      className={`footer__nav ${
        !show && breakpoints.md && "footer__nav-padding"
      } ${breakpoints.md && lastElement && "footer__last-element-margin"}`}
    >
      <div
        onClick={() => breakpoints.md && setShow(!show)}
        className={`footer__nav-heading ${
          breakpoints.md && "footer__arrow-icon"
        } ${breakpoints.md && show && "footer__arrow-icon-rotate"} `}
      >
        {menuItem.label}
      </div>
      <div className={`footer__links-slide-wrapper `}>
        <Collapse isOpened={breakpoints.md ? show : true}>
          {menuItem.childItems.nodes.map((child, i) => (
            <div key={i}>
              <UniversalLink
                to={child.path}
                className="footer__nav-link"
                target={child.target}
              >
                {child.label}
              </UniversalLink>
            </div>
          ))}
        </Collapse>
      </div>
    </div>
  )
}

export default FooterNavigation
