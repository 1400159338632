import React, { useState } from "react"
import { Collapse } from "react-collapse"
import SVGParser from "../SVGParser"
import UniversalLink from "../UniversalLink"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const NavigationDropdown = ({ parent, children, setdropDownOpen }) => {
  const [show, setShow] = useState(false)

  function toggle(value) {
    setShow(value)
    setdropDownOpen(value)
  }
  const breakpoints = useBreakpoint()
  const isLg = breakpoints.lg

  return (
    <div onMouseLeave={() => (!isLg ? toggle(false) : false)}>
      <div>
        <div
          className={`navigation__link has--children ${show ? "is--open" : ""}`}
          onClick={() => toggle(!show)}
          onMouseEnter={() => (!isLg ? toggle(true) : false)}
        >
          {parent.label}
          <SVGParser name="chevron-down-small-bold" />
        </div>

        <ul
          className={`navigation__dropdown`}
          onMouseLeave={() => (!isLg ? toggle(false) : false)}
        >
          <Collapse isOpened={show}>
            {children.map(child => (
              <li className="navigation__subitem" key={child.id}>
                <UniversalLink
                  to={child.path}
                  className="navigation__link navigation__link--sub"
                  activeClassName="is--active"
                >
                  {child.acf.icon ? <SVGParser name={child.acf.icon} /> : ""}
                  {child.label}
                </UniversalLink>
              </li>
            ))}
          </Collapse>
        </ul>
      </div>
    </div>
  )
}

export default NavigationDropdown
