import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import parseHTML from "html-react-parser"
import "./footer.scss"
import FooterNavigation from "./footerNavigation"
import UniversalLink from "../UniversalLink"
import SVGParser from "../SVGParser"

const Footer = ({ cleanFooter = false }) => {
  const { footerMenuQuery, legalMenuQuery, wp } = useStaticQuery(
    graphql`
      {
        footerMenuQuery: wpMenu(slug: { eq: "footer" }) {
          menuItems {
            nodes {
              id
              url
              label
              parentId
              path
              childItems {
                nodes {
                  url
                  label
                  path
                }
              }
            }
          }
        }
        legalMenuQuery: wpMenu(slug: { eq: "legal" }) {
          menuItems {
            nodes {
              url
              label
              parentId
              path
              childItems {
                nodes {
                  url
                  label
                  path
                }
              }
            }
          }
        }
        wp {
          themeOptions {
            ACFThemePageLink {
              copyrightText
              footerRulesText
              appleStoreLink
              googlePlayStoreLink
              loginLink {
                title
                url
              }
              registerLink {
                title
                url
              }
              socialMediaLinks {
                icon
                link
              }
            }
          }
        }
      }
    `
  )

  const footerMenu =
    footerMenuQuery && footerMenuQuery.menuItems
      ? footerMenuQuery.menuItems.nodes.filter(
          menuItem => menuItem.parentId === null
        )
      : null

  const legalMenu = legalMenuQuery.menuItems
    ? legalMenuQuery.menuItems.nodes.filter(
        menuItem => menuItem.parentId === null
      )
    : null

  const themeOptions = wp.themeOptions.ACFThemePageLink
  const year = new Date().getFullYear()

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          {footerMenu && !cleanFooter ? (
            <div className="footer__nav-wrapper">
              {footerMenu.map((menuItem, i) => (
                <FooterNavigation
                  menuItem={menuItem}
                  key={i}
                  lastElement={i + 1 == footerMenu.length}
                />
              ))}
            </div>
          ) : null}
          {cleanFooter && (
            <div className="footer__logo-wrapper">
              <div className="footer__logo"></div>
            </div>
          )}
          {themeOptions.googlePlayStoreLink || themeOptions.appleStoreLink ? (
            <div className="footer__download">
              <div className="footer__section-title">App Download</div>
              <div className="footer__app-wrapper">
                {themeOptions.googlePlayStoreLink && (
                  <a
                    href={themeOptions.googlePlayStoreLink}
                    className="footer__store"
                    target="blank"
                  >
                    <SVGParser name={"playstore"} />
                  </a>
                )}
                {themeOptions.appleStoreLink && (
                  <a
                    href={themeOptions.appleStoreLink}
                    target="blank"
                    className="footer__store"
                  >
                    <SVGParser name={"appstore"} />
                  </a>
                )}
              </div>
            </div>
          ) : null}
        </div>

        <div>
          <div className="footer__links-wrapper">
            {legalMenu
              ? legalMenu.map((legal, i) => (
                  <UniversalLink
                    key={i}
                    to={legal.path}
                    className="footer__link"
                  >
                    {legal.label}
                  </UniversalLink>
                ))
              : null}
          </div>
          <div className="footer__text">
            © {year} {themeOptions.copyrightText}
          </div>
          {themeOptions.footerRulesText && !cleanFooter && (
            <div className="footer__rules">
              {parseHTML(themeOptions.footerRulesText)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Footer
