import React, { useEffect, useState, useContext } from "react"
import notificationContext from "../../context/NotificationContext"
import SVGParser from "../SVGParser"
import "./Notification.scss"

const Notification = ({ message }) => {
  const delay = 4000
  const transitionDuration = 300
  const { setMessage } = useContext(notificationContext)
  const [show, setShow] = useState(false)

  useEffect(() => {
    let timer
    let timerMessage

    if (message) {
      setShow(true)

      timer = setTimeout(() => {
        setShow(false)

        timerMessage = setTimeout(() => {
          setMessage("")
        }, transitionDuration)
      }, delay)
    }

    return () => {
      clearTimeout(timer)
      clearTimeout(timerMessage)
    }
  }, [message])

  return (
    <div className={`notification ${show && message ? "is--visible" : ""}`}>
      <SVGParser name="message-success" classes="notification__icon" />
      <div className="notification__message">{message}</div>
    </div>
  )
}

export default Notification
