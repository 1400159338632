import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import HTMLReactParser from "html-react-parser"
import UniversalLink from "../UniversalLink"
import "./overlay.scss"
import axios from "axios"

import SVGParser from "../SVGParser"
import { navigate } from "gatsby-link"
const Overlay = props => {
  const { wp } = useStaticQuery(graphql`
    query Overlay {
      wp {
        themeOptions {
          ACFThemePageLink {
            overlay {
              headline
              headlineHelper
              link {
                title
                url
              }
              notifyHeading
              notifyLegal
              notifyPlaceholder
              notifySubheading
              subheading
            }
          }
        }
      }
    }
  `)

  const {
    headline,
    subheading,
    headlineHelper,
    link,
    notifyHeading,
    notifyLegal,
    notifyPlaceholder,
    notifySubheading,
  } = wp.themeOptions.ACFThemePageLink.overlay

  const [showSuccess, setShowSuccess] = useState(false)

  const { showOverlay, setShowOverlay } = props
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)

  const emailValidation = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (!email || regex.test(email) === false) {
      setError(true)
      return false
    }
    setError(false)
    return true
  }

  const onSubmit = async () => {
    if (emailValidation()) {
      const data = {
        email: email,
        "opt-in": "METROFS_NOTIFICATION",
        type: "WAITING_LIST",
        data: null,
      }
      const instance = axios.create({
        baseURL: process.env.GATSBY_API_BASE_URI,
        headers: {
          "Content-Type": "application/json",
        },
      })
      const result = await instance.post(`/user-marketing-email-api`, data)
      setShowSuccess(true)
    }
  }

  return (
    <div className={`overlay ${showOverlay ? "is--active" : ""}`}>
      <div className="container">
        <a
          className="overlay__close-btn"
          onClick={() => {
            setShowOverlay(false)
            setShowSuccess(false)
            document.body.style.overflow = "visible"
          }}
        >
          <SVGParser name="overlay-close" />
        </a>
        {showSuccess ? (
          <div className="overlay__success">
            <SVGParser name="check-outline-large" />
            <div className="overlay__success-title">
              Vielen Dank! <br />
              Sie werden bald von uns hören!
            </div>
            <button
              onClick={() => {
                setShowOverlay(false)
                setShowSuccess(false)
                navigate("/")
              }}
              className="button"
            >
              Weiter zur Startseite
            </button>
          </div>
        ) : (
          <div className="overlay__wrapper">
            <div className="overlay__wrapper-top">
              <h2 className="overlay__heading">{headline}</h2>
              <div className="overlay__headingHelper">{headlineHelper}</div>
              <h6 className="overlay__subheading">{subheading}</h6>
              {link && (
                <UniversalLink to={link.url} className="overlay__button button">
                  {link.title}
                </UniversalLink>
              )}
            </div>
            <h6 className="overlay__notify-heading">{notifyHeading}</h6>
            <div className="overlay__notify-subheading">{notifySubheading}</div>
            <div className="overlay__search-wrapper">
              <span className="overlay__search-icon" onClick={() => onSubmit()}>
                <SVGParser name="send-email" />
              </span>
              <input
                className={`overlay__input ${error ? "has--error" : ""}`}
                placeholder={notifyPlaceholder}
                type="email"
                id="email"
                name="email"
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            {notifyLegal && (
              <div className="overlay__legal">
                {HTMLReactParser(notifyLegal)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Overlay
