import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import contentParser from "gatsby-wpgraphql-inline-images"
import config from "../../../config"
import Cookies from "universal-cookie"
import "./CookieBanner.scss"
import UniversalLink from "../UniversalLink"

const cookies = new Cookies()
const cookieName = "allowStatistics"
const cookieNameFunctional = "allowFunctional"
const cookieNameThirdParty = "allowThirdParty"

const CookieBanner = () => {
  const { wp } = useStaticQuery(graphql`
    query CookieBannerQuery {
      wp {
        themeOptions {
          ACFThemePageLink {
            cookieBannerAcceptLabel
            cookieBannerContent
            cookiesPageLink {
              title
              url
            }
            cookieBannerTitle
          }
        }
      }
    }
  `)

  const cookieBannerFields = wp.themeOptions.ACFThemePageLink
  const cookieValue = cookies.get(cookieName)
  const [show, setShow] = useState(false)

  useEffect(() => {
    const url = typeof window !== "undefined" ? window.location.href : ""
    if (url.search("cookies") == -1) {
      setShow(!cookieValue ? true : false)
    }
  })

  const setCookieBanner = value => {
    setShow(false)
    if (value) {
      cookies.set(cookieName, "true", config.cookieSettings)
      cookies.set(cookieNameFunctional, "true", config.cookieSettings)
      cookies.set(cookieNameThirdParty, "true", config.cookieSettings)
    }
  }

  return (
    <div className={`cookie-banner ${show ? "is--visible" : ""}`}>
      <div className="container">
        <div className="cookie-banner__row">
          <div className="cookie-banner__inner">
            <div className="cookie-banner__title">
              {cookieBannerFields.cookieBannerTitle}
            </div>

            <div className="cookie-banner__text">
              {contentParser(
                { content: cookieBannerFields.cookieBannerContent },
                config.inlineImagesOptions
              )}
            </div>
          </div>

          <div className="cookie-banner__buttons">
            <button
              className="cookie-banner__button button is--smaller-button"
              onClick={() => setCookieBanner(true)}
            >
              {cookieBannerFields.cookieBannerAcceptLabel}
            </button>

            {cookieBannerFields.cookiesPageLink && (
              <UniversalLink
                onClick={() => setShow(false)}
                to={cookieBannerFields.cookiesPageLink.url}
                className="cookie-banner__button button is--inverted is--smaller-button"
              >
                {cookieBannerFields.cookiesPageLink.title}
              </UniversalLink>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieBanner
