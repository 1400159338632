import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default ({
  image: {
    localFile: { childImageSharp, extension, publicURL },
    altText,
  },
  classes,
  ...other
}) => {
  return (
    <>
      {!childImageSharp && extension === "svg" ? (
        <img
          loading="lazy"
          className={`${classes} is--svg`}
          src={publicURL}
          alt={altText}
        />
      ) : (
        <GatsbyImage
          className={classes}
          objectFit="contain"
          image={childImageSharp.gatsbyImageData}
          alt={altText}
          {...other}
        />
      )}
    </>
  )
}
