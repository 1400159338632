import React from "react"

import supportIcon from "../images/icons/support-02.svg"
import card2 from "../images/icons/card-2.svg"
import loginid from "../images/icons/login-id.svg"
import metroGarantie from "../images/icons/metro-garantie.svg"
import placeholder from "../images/icons/icon-placeholder.svg"
import numberOneIcon from "../images/icons/number-1.svg"
import numberTwoIcon from "../images/icons/number-2.svg"
import numberThreeIcon from "../images/icons/number-3.svg"
import cashBack from "../images/icons/cashback.svg"
import faqIcon from "../images/icons/faq-icon.svg"
import ArrowIcon from "../images/icons/arrow-long-right.svg"
import WhiteRates from "../images/icons/white-rates.svg"
import DelayPayment from "../images/icons/delay-payment.svg"
import Chevron from "../images/icons/chevron-down-small.svg"
import ChevronDownBold from "../images/icons/chevron-down-small-bold.svg"
import AlertInfoSmall from "../images/icons/alert-info-small.svg"
import Card from "../images/icons/card.svg"
import Rates from "../images/icons/rates.svg"
import Support04 from "../images/icons/support-04.svg"
import Dialog from "../images/icons/dialog.svg"
import WriteEmail from "../images/icons/write-email.svg"
import Contactless from "../images/icons/contactless.svg"
import Security from "../images/icons/security.svg"
import NotificationDisabled from "../images/icons/notification-disabled.svg"
import Lock from "../images/icons/lock.svg"
import AddCard from "../images/icons/add-card.svg"
import EarlyRepayment from "../images/icons/early-repayment.svg"
import PDFIcon from "../images/icons/pdf-icon.svg"
import DOCIcon from "../images/icons/doc-icon.svg"
import Download from "../images/icons/download.svg"
import Promotion from "../images/icons/promotion.svg"
import InfoCircle from "../images/icons/info-circle.svg"
import TouchId from "../images/icons/touch-id.svg"
import CheckOutline from "../images/icons/check-outline.svg"
import CheckOutlineLarge from "../images/icons/check-outline-large.svg"
import App from "../images/icons/app.svg"
import SecureCheck from "../images/icons/secure-check.svg"
import WorldWide from "../images/icons/worldwide.svg"
import MasterCard from "../images/icons/mastercard.svg"
import Dashboard from "../images/icons/dashboard.svg"
import ReferAFriend from "../images/icons/refer-a-friend.svg"
import Insta from "../images/icons/instagram-icon.svg"
import Linkedin from "../images/icons/linkedin-icon.svg"
import Appstore from "../images/icons/appstore.svg"
import Playstore from "../images/icons/playstore.svg"
import XCircle from "../images/icons/x-circle.svg"
import MenuClose from "../images/icons/mobile-menu-close.svg"
import CheckSmallBold from "../images/icons/check-small-bold.svg"
import ForFreeBadge from "../images/icons/for-free-badge.svg"
import SendEmail from "../images/icons/send-email.svg"
import OverlayClose from "../images/icons/overlay-close.svg"
import Angebot from "../images/icons/angebot.svg"
import Beta from "../images/icons/beta.svg"
import Bezahlen from "../images/icons/bezahlen.svg"
import Karte from "../images/icons/karte.svg"
import Kartenverlust from "../images/icons/kartenverlust.svg"
import Kontoeinstellungen from "../images/icons/kontoeinstellungen.svg"
import Kundenkonto from "../images/icons/kundenkonto.svg"
import Registrierung from "../images/icons/registrierung.svg"
import Unternehmen from "../images/icons/unternehmen.svg"
import MessageSuccess from "../images/icons/message-success.svg"
import PlayCircle from "../images/icons/play-circle.svg"

const components = {
  "support-02": supportIcon,
  "login-id": loginid,
  "metro-garantie": metroGarantie,
  "icon-placeholder": placeholder,
  "number-1": numberOneIcon,
  "number-2": numberTwoIcon,
  "number-3": numberThreeIcon,
  cashback: cashBack,
  "faq-icon": faqIcon,
  "arrow-long-right": ArrowIcon,
  "white-rates": WhiteRates,
  "delay-payment": DelayPayment,
  "chevron-down-small": Chevron,
  "card-2": card2,
  "chevron-down-small-bold": ChevronDownBold,
  "alert-info-small": AlertInfoSmall,
  card: Card,
  rates: Rates,
  "support-04": Support04,
  dialog: Dialog,
  "write-email": WriteEmail,
  lock: Lock,
  "notification-disabled": NotificationDisabled,
  security: Security,
  contactless: Contactless,
  "add-card": AddCard,
  "early-repayment": EarlyRepayment,
  "pdf-icon": PDFIcon,
  "doc-icon": DOCIcon,
  download: Download,
  promotion: Promotion,
  "info-circle": InfoCircle,
  "touch-id": TouchId,
  "check-outline": CheckOutline,
  "check-outline-large": CheckOutlineLarge,
  app: App,
  "secure-check": SecureCheck,
  worldwide: WorldWide,
  mastercard: MasterCard,
  dashboard: Dashboard,
  "refer-a-friend": ReferAFriend,
  "instagram-icon": Insta,
  "linkedin-icon": Linkedin,
  playstore: Playstore,
  appstore: Appstore,
  "x-circle": XCircle,
  "mobile-menu-close": MenuClose,
  "check-small-bold": CheckSmallBold,
  "for-free-badge": ForFreeBadge,
  "send-email": SendEmail,
  "overlay-close": OverlayClose,
  angebot: Angebot,
  beta: Beta,
  bezahlen: Bezahlen,
  karte: Karte,
  kartenverlust: Kartenverlust,
  kontoeinstellungen: Kontoeinstellungen,
  kundenkonto: Kundenkonto,
  registrierung: Registrierung,
  unternehmen: Unternehmen,
  "message-success": MessageSuccess,
  "play-circle": PlayCircle,
}

const SVGParser = ({ name, classes, key, ...other }) => {
  const Component = components[name]
  if (Component) {
    return <Component id={key} className={classes} {...other} />
  } else return null
}

export default SVGParser
