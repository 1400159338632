import { useEffect } from "react"
import axios from "axios"

const FreshChat = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      async function authenticateCB(uuid) {
        const aionJwtGenerateApi =
          process.env.GATSBY_API_BASE_URI + "/aion-freshworks-jwt-api"

        const res = await axios.post(
          aionJwtGenerateApi,
          { freshchatUuid: uuid },
          {
            headers: {
              accept: "*/*",
              "Content-Type": "application/json",
            },
          }
        )

        const signedUUID = res.data.jwt

        window.fcWidget.authenticate(signedUUID)
      }

      const widgetConfig = {
        token: process.env.GATSBY_FRESHCHAT_TOKEN,
        host: "https://wchat.eu.freshchat.com",
        tags: ["0027xq7a9y_logged_out"],
        config: {
          cssNames: {
            widget: "custom_fc_frame",
          },
          headerProperty: {
            // hideChatButton: 768,
            direction: "ltr",
            backgroundColor: "#0064fe",
          },
        },
      }

      const authenticateUser = async userData => {
        if (userData && userData.freshchat_uuid) {
          await authenticateCB(userData.freshchat_uuid)
        } else {
          // Generate UUID and create new user
          const resp = await window.fcWidget.user.getUUID()
          let uuid = resp?.data?.uuid

          await authenticateCB(uuid)
        }
      }

      window.fcWidget.on("frame:statechange", function (data) {
        if (
          data.success === false &&
          data.data.frameState === "not_authenticated"
        ) {
          authenticateUser(data)
        }
      })

      window.fcWidget.on("user:statechange", function (data) {
        if (data.success) {
          let userData = data.data
          // authenticate user success
          if (userData) {
            if (userData.userState === "authenticated") {
              console.log("User Authenticated")
            }
            if (userData.userState === "created") {
              console.log("User Created")
            }
            if (userData.userState === "loaded") {
              console.log("User Loaded")
            }
            if (userData.userState === "identified") {
              console.log("User Identified")
            }
            if (userData.userState === "restored") {
              console.log("User Restored")
            }
          }
        } else {
          let userData = data.data
          if (userData) {
            if (
              userData.userState === "not_loaded" ||
              userData.userState === "unloaded" ||
              userData.userState === "not_created" ||
              userData.userState === "not_authenticated"
            ) {
              authenticateUser(userData)
            }
          }
        }
      })

      window.fcWidget.init(widgetConfig)
    }
  })

  return null
}

export default FreshChat
