import { Helmet } from "react-helmet"
import React from "react"

const Head = () => {
  return (
    <Helmet>
      <script>var faCookieExp = 30;</script>
      <script src="https://fat.financeads.net/fpc.js"></script>
    </Helmet>
  )
}

export default Head
