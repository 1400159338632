import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import "./Header.scss"
import "./Burger.scss"
import Navigation from "./Navigation"

import ScrollDirection from "../../utils/ScrollDirection"
import UniversalLink from "../UniversalLink"

const Header = ({
  menuColor,
  hideNavigation,
  hideButton,
  whiteBackgroundMenu,
}) => {
  const [show, setShow] = useState(false)
  const [hasbg, setHasbg] = useState(false)
  const [dropDownOpen, setdropDownOpen] = useState(false)

  const { menuQuery, wp } = useStaticQuery(
    graphql`
      {
        wp {
          themeOptions {
            ACFThemePageLink {
              landingPageLink {
                title
                url
              }
            }
          }
        }
      }
    `
  )

  const themeOptions = wp.themeOptions.ACFThemePageLink
  const landingPageLink = themeOptions.landingPageLink

  const scrollDirection = ScrollDirection()
  function setBG() {
    if (window.pageYOffset > 50) {
      setHasbg(true)
    } else {
      setHasbg(false)
    }
  }

  function setThemeColor() {
    if (typeof window === "undefined" || !window.document) {
      return
    }

    const themeColorTag = document.head.querySelector(
      'meta[name="theme-color"]'
    )

    if (window.pageYOffset > 700) {
      themeColorTag.setAttribute("content", "#ffffff")
    } else if (!window.location.href.includes("uber-uns")) {
      themeColorTag.setAttribute("content", "#ffed00")
    }
  }

  useEffect(() => {
    setBG()
    setThemeColor()

    window.onscroll = () => {
      setBG()
      setThemeColor()
    }
  }, [])

  return (
    <header
      className={`header ${show ? "is--open" : ""} ${
        whiteBackgroundMenu || hasbg || dropDownOpen ? "has--bg" : ""
      } ${menuColor && menuColor !== null ? "is--white-menu" : ""}`}
      style={{
        transform: `translateY(${
          scrollDirection === "up" || show || dropDownOpen ? 0 : "-100%"
        })`,
      }}
    >
      <div className="header__inner container">
        <Link to="/" className="header__logo"></Link>
        {!hideNavigation && hideNavigation === null ? (
          <>
            <Navigation
              show={show}
              setdropDownOpen={setdropDownOpen}
              setShow={setShow}
            />
            <button
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              className={`burger ${show ? "is--active" : ""}`}
              data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={() => (show ? setShow(false) : setShow(true))}
            ></button>
          </>
        ) : landingPageLink && !hideButton && hideButton === null ? (
          <UniversalLink
            to={landingPageLink.url}
            className="button header__landing-page-button"
          >
            {landingPageLink.title}
          </UniversalLink>
        ) : null}
      </div>
    </header>
  )
}

export default Header
