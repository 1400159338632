import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Collapse } from "react-collapse"
import LoginId from "../../images/icons/login-id.svg"
import { withBreakpoints } from "gatsby-plugin-breakpoints"

import "./Navigation.scss"
import NavigationItem from "./NavigationItem"
import ThemeOptions from "../../utils/themeOptions"
import UniversalLink from "../UniversalLink"

export default withBreakpoints(
  ({ show, breakpoints, setdropDownOpen, setShow }) => {
    const [rendered, setRendered] = useState(false)

    const { menuQuery, wp } = useStaticQuery(
      graphql`
        {
          menuQuery: wpMenu(slug: { eq: "primary" }) {
            menuItems {
              nodes {
                id
                path
                target
                url
                label
                description
                cssClasses
                parentId
                childItems {
                  nodes {
                    id
                    path
                    target
                    title
                    url
                    label
                    acf {
                      icon
                    }
                  }
                }
              }
            }
          }
          wp {
            themeOptions {
              ACFThemePageLink {
                copyrightText
                appleStoreLink
                googlePlayStoreLink
                loginLink {
                  title
                  url
                }
                registerLink {
                  title
                  url
                }
                socialMediaLinks {
                  icon
                  link
                }
              }
            }
          }
        }
      `
    )

    useEffect(() => {
      setRendered(true)
    })

    const themeOptions = wp.themeOptions.ACFThemePageLink

    const menu =
      menuQuery && menuQuery.menuItems
        ? menuQuery.menuItems.nodes.filter(
            menuItem => menuItem.parentId === null
          )
        : null

    const login = themeOptions.loginLink
    const register = themeOptions.registerLink

    function getNavigation() {
      return (
        <div className="navigation__inner">
          {menu ? (
            <ul className="navigation__list" id="navigation">
              {menu.map(menuItem => {
                return (
                  <NavigationItem
                    menuItem={menuItem}
                    key={menuItem.id}
                    setShow={setShow}
                    setdropDownOpen={setdropDownOpen}
                  />
                )
              })}
            </ul>
          ) : null}
          <div className="navigation__buttons">
            {login ? (
              <UniversalLink to={login.url} className="navigation__login">
                <LoginId />
                {login.title}
              </UniversalLink>
            ) : null}
            {register ? (
              <UniversalLink
                to={register.url}
                className="button"
                id="register-link"
              >
                {register.title}
              </UniversalLink>
            ) : null}
          </div>
        </div>
      )
    }

    return (
      <div
        className="navigation"
        style={{ display: !rendered ? "none" : "block" }}
      >
        {breakpoints.lg ? (
          <Collapse isOpened={show} onRest={false}>
            {getNavigation()}
          </Collapse>
        ) : (
          getNavigation()
        )}
      </div>
    )
  }
)
